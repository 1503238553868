"use client";

import React, { ReactElement } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { assets } from "@koble/assets/assets";
import config from "@koble/config";
import ssoRouteManager from "@koble/config/src/ssoRouteManager";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Col, Row } from "antd";
import Link from "next/link";

// x-release-please-start-version
const MainLayout = ({ children }: { children: ReactElement }) => {
  const content = (
    <>
      <Link href={ssoRouteManager.home()}>
        <img
          width={110}
          src={assets.KOBLE_LOGO_COLOR_WITH_TEXT}
          alt="Koble logo"
        />
      </Link>
      <div style={{ height: 30 }} />
      {children}
    </>
  );
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={config.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
    >
      <GoogleOAuthProvider clientId={config.NEXT_PUBLIC_GOOGLE_CLIENT_ID}>
        <Row>
          <Col xs={24} md={0}>
            <div
              style={{
                backgroundColor: "#fff",
                borderRadius: 16,
                paddingTop: 32,
                paddingRight: 24,
                paddingBottom: 32,
                paddingLeft: 24,
              }}
            >
              {content}
            </div>
          </Col>
          <Col xs={0} md={24}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                backgroundColor: "#f5f5f5",
              }}
            >
              <Row
                gutter={24}
                style={{
                  width: "100%",
                  maxWidth: 1200,
                  height: "100%",
                  maxHeight: 700,
                  overflow: "auto",
                }}
              >
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                  xl={{ span: 10 }}
                >
                  <div
                    style={{
                      backgroundColor: "#fff",
                      borderRadius: 16,
                      paddingTop: 32,
                      paddingRight: 24,
                      paddingBottom: 32,
                      paddingLeft: 24,
                      height: "100%",
                    }}
                  >
                    {content}
                  </div>
                </Col>

                <Col
                  xs={{ span: 0 }}
                  sm={{ span: 0 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                  xl={{ span: 14 }}
                >
                  <div
                    style={{
                      borderRadius: 16,
                      backgroundImage: `url("${assets.SSO_STUDENT}")`,
                      backgroundSize: "cover",
                      backgroundPosition: "center left",
                      height: "100%",
                      width: "100%",
                    }}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </GoogleOAuthProvider>
    </GoogleReCaptchaProvider>
  );
};

export default MainLayout;
